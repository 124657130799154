import {react as React, useState} from 'react';  

import { Box, Button, Typography , TextField , MenuItem  } from '@mui/material';

import { Link } from 'react-router-dom';

import { getAuth } from 'firebase/auth';

// get year, branch, spec, section from user input

export const Tapage = () => {
    const [year, setYear] = useState(1);
    const [branch, setBranch] = useState('cse');
    const [spec, setSpec] = useState('cs');
    const [section, setSection] = useState('a');

    //check if user is logged in
    // if not redirect to login page
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
        window.location.href = '/auth';
    }


    return (
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <Typography variant="h3" style={{ margin: '20px' }}>SRM CAL</Typography>
            <Box style={{ margin: '40px', padding: '20px', border: '2px solid lightgrey', borderRadius: '20px', backgroundColor: 'White' , justifyContent : "center", width: '300px' }}>
                <TextField
                    select
                    label="Year"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    style={{ padding: '20px 0', width: '100%' }}
                >
                    <MenuItem value={1}>1st Year</MenuItem>
                    <MenuItem value={2}>2nd Year</MenuItem>
                    <MenuItem value={3}>3rd Year</MenuItem>
                    <MenuItem value={4}>4th Year</MenuItem>
                </TextField><br />

                <TextField
                    select
                    label="Branch"
                    value={branch}
                    onChange={(e) => setBranch(e.target.value)}
                    style={{ padding: '20px 0', width: '100%' }}
                >
                    <MenuItem value="cse">CSE</MenuItem>
                    <MenuItem value="it">IT</MenuItem>
                    <MenuItem value="mech">MEC</MenuItem>
                    <MenuItem value="eee">EEE</MenuItem>
                </TextField><br />

                <TextField
                    select
                    label="Specialization"
                    value={spec}
                    onChange={(e) => setSpec(e.target.value)}
                    style={{ padding: '20px 0', width: '100%' }}
                >
                    <MenuItem value="core">Core</MenuItem>
                    <MenuItem value="cs">Cybersecurity</MenuItem>
                    <MenuItem value="bda">BDA</MenuItem>
                    <MenuItem value="cc">Cloud Computing</MenuItem>
                    <MenuItem value="gt">Gaming Technology</MenuItem>
                    <MenuItem value="aiml">AIML</MenuItem>
                    <MenuItem value="iot">IOT</MenuItem>
                    <MenuItem value="csbs">CSBS</MenuItem>
                </TextField><br />

                <TextField
                    select
                    label="Section"
                    value={section}
                    onChange={(e) => setSection(e.target.value)}
                    style={{ padding: '20px 0', width: '100%' }}
                >
                    {Array.from(Array(26), (_, i) => String.fromCharCode(97 + i)).map((letter) => (
                        <MenuItem key={letter} value={letter}>{letter}</MenuItem>
                    ))}
                </TextField><br />

                <Link to={`/srmcal/${year}/${branch}/${spec}/${section}`}>
                    <Button variant="outlined" style={{ margin: '', padding: '20px', width: '100%' }}>View Time Table</Button>
                </Link><br />
            </Box>
        </Box>
    )
}