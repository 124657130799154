//coming soon
import React from 'react';
import { Box, Typography } from '@mui/material';



export const Finder = () => {
    return (
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <Typography variant="h3" style={{ margin: '20px' }}>Coming Soon</Typography>
        </Box>
    )
}

