
import { auth } from '../config/firebase';
import { createUserWithEmailAndPassword, signInAnonymously, signOut, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useState } from 'react';

import { Box, Button, TextField, Typography } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';


export const Auth = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const redirecthome = () => {
        window.location.href = '/';
    }

    const SignUP = async () => {
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            alert('User created');
        } catch (error) {
            alert(error.message)
        }
    };

    const SignInAnonymously = async () => {
        try {
            await signInAnonymously(auth);
            alert('Signed In Anonymously');
            redirecthome();
        } catch (error) {
            alert(error.message)
        }
    }

    const SignIn = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            alert('Signed In');
            redirecthome();
        } catch (error) {
            alert(error.message)
        }
    }

    const SignOut = async () => {
        try {
            await signOut(auth);
            alert('Signed Out');
            redirecthome();
        } catch (error) {
            alert(error.message)
        }
    }

    const GoogleSignIn = async () => {
        try {
            const provider = new GoogleAuthProvider();
            await signInWithPopup(auth, provider);
            alert('Signed In with Google');
            redirecthome();
        } catch (error) {
            alert(error.message)
        }
    }


    // check if user is signed in
    const user = auth.currentUser;
    if (user) {
        if (user.isAnonymous) {
            return (
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
                    <Typography variant="h3" style={{ margin: '20px' }}>Authentication</Typography>
                        <Typography variant="h5">Signed In Anonymously</Typography>
                        <Button variant="outlined" onClick={SignOut} style={{ margin: '20px', padding: '20px', width: '300px' }}>Sign Out</Button>
                </Box>
            );
        } else {
            // check if google login
            if (user.providerData[0].providerId === 'google.com') {
                if (user.email.includes("srmist.edu.in")) {
                return (
                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
                        <Typography variant="h3" style={{ margin: '20px' }}>Authentication</Typography>
                        <Typography variant="h5">Signed In with Google as {user.email}</Typography>
                        <Typography variant="h5">Email Verified as SRM STUDENT </Typography>
                        <Button variant="outlined" onClick={SignOut} style={{ margin: '20px', padding: '20px', width: '300px' }}>Sign Out</Button>
                    </Box>
                );
                } else {
                    return (
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
                            <Typography variant="h3" style={{ margin: '20px' }}>Authentication</Typography>
                            <Typography variant="h5">Signed In with Google as {user.email}</Typography>
                            <Typography variant="h5">Email Not Verified as SRM STUDENT </Typography>
                            <Button variant="outlined" onClick={SignOut} style={{ margin: '20px', padding: '20px', width: '300px' }}>Sign Out</Button>
                        </Box>
                    );
                }
            } else {
                return (
                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
                        <Typography variant="h3" style={{ margin: '20px' }}>Authentication</Typography>
                        <Typography variant="h5">Signed In with Email as {user.email}</Typography>
                        <Button variant="outlined" onClick={SignOut} style={{ margin: '20px', padding: '20px', width: '300px' }}>Sign Out</Button>
                    </Box>
                );
            
            }
        }
        
    }

    else {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
                <Typography variant="h3" style={{ margin: '20px' }}>Authentication</Typography>
                <Box style={{ margin: '20px', padding: '20px', border: '2px solid lightgrey', borderRadius: '20px', backgroundColor: 'White' , justifyContent : "center", alignItems : "center" , display : "flex" , flexDirection : "column" }}>
                    <TextField placeholder="Email" onChange={(e) => setEmail(e.target.value)} style={{ padding: '20px', width: '300px' }} />
                    <TextField placeholder="Password" type='password' onChange={(e) => setPassword(e.target.value)} style={{ padding: '20px', width: '300px' }} />
                    <ButtonGroup variant="outlined" aria-label="Basic button group" style={{ margin: '20px', }}>
                        <Button onClick={SignUP} style={{ padding: '20px', width: '150px' }}>Sign UP</Button>
                        <Button onClick={SignIn} style={{ padding: '20px', width: '150px' }}>Sign in</Button>
                    </ButtonGroup>

                    <ButtonGroup variant="outlined" aria-label="Basic button group" style={{ margin: '20px', }}>
                        <Button onClick={SignInAnonymously} style={{ padding: '20px', width: '150px' }}>Sign In Anonymously</Button>
                        <Button onClick={GoogleSignIn} style={{ padding: '20px', width: '150px' }}>Sign In With Google</Button>
                    </ButtonGroup>
                    <Button variant="outlined" onClick={SignOut} style={{ margin: '20px', padding: '20px', width: '300px' }}>Sign Out</Button>
                </Box>
            </Box>
        );
    }
}