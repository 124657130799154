import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

export const Home = () => {
    // const history = useHistory();
    return (

        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <Typography variant="h3" style={{ margin: '20px' }}>SRM CAL</Typography>
            <Box style={{ margin: '20px', padding: '20px', border: '2px solid lightgrey', borderRadius: '20px', backgroundColor: 'White' }}>
                <Link to="/auth">       <Button variant="outlined" style={{ margin: '20px', padding: '20px', width: '300px' }}>Login        </Button>       </Link><br />
                <Link to="/table">      <Button variant="outlined" style={{ margin: '20px', padding: '20px', width: '300px' }}>Time Table   </Button>       </Link><br />
                <Link to="/calender">   <Button variant="outlined" style={{ margin: '20px', padding: '20px', width: '300px' }}>Calender     </Button>       </Link><br />
                <Link to="/finder">     <Button variant="outlined" style={{ margin: '20px', padding: '20px', width: '300px' }}>Staff Finder </Button>       </Link><br />
            </Box>
        </Box>
    )
};