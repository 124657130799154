// get data from firebase datastore
// /srmcal/year1/cse/cs/a

import React, { useEffect } from 'react';
import { Box, TableCell, TableRow, } from '@mui/material';
import { db } from '../config/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useState } from 'react';
import { Table, TableHead } from '@mui/material';
import { TableBody } from '@mui/material';
import { getAuth } from 'firebase/auth';

// url params 

import { useParams } from 'react-router-dom';





export const Rendertable = () => {
    const [data, setData] = useState([]);

    //const tableref = collection(db, "srmcal", "year1", "cse", "cs", "a");
    const { year, branch, spec, section } = useParams();
    const tableref = collection(db, "srmcal", "year" + year, branch, spec, section);
    useEffect(() => {

        const getData = async () => {

            try {

                const data = await getDocs(tableref);
                // console.log(data);
                const filtereddata = data.docs.map((doc) => doc.data());
                // sort data based on day
                filtereddata.sort((a, b) => a.day - b.day);
                //replace day number with day name
                filtereddata.forEach((element) => {
                    switch (element.day) {
                        case 1:
                            element.day = "Monday";
                            break;
                        case 2:
                            element.day = "Tuesday";
                            break;
                        case 3:
                            element.day = "Wednesday";
                            break;
                        case 4:
                            element.day = "Thursday";
                            break;
                        case 5:
                            element.day = "Friday";
                            break;
                        default:
                            break;
                    }
                });
                setData(filtereddata);

            } catch (err) {
                alert(err.message);
            }
        };

        getData();
    }, [tableref]);
    

    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
        window.location.href = '/auth';
    }

    return (
        // center align the table
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <Table sx={{ width: '90%', margin: 'auto', marginTop: '20px', borderradius: '20px', border: '2px solid lightgrey', backgroundColor: 'White' }}>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Day</strong></TableCell>
                        <TableCell><strong>Period 1</strong></TableCell>
                        <TableCell><strong>Period 2</strong></TableCell>
                        <TableCell><strong>BREAK</strong></TableCell>
                        <TableCell><strong>Period 3</strong></TableCell>
                        <TableCell><strong>Period 4</strong></TableCell>
                        <TableCell><strong>LUNCH</strong></TableCell>
                        <TableCell><strong>Period 5</strong></TableCell>
                        <TableCell><strong>Period 6</strong></TableCell>
                        <TableCell><strong>Period 7</strong></TableCell>
                        <TableCell><strong>Period 8</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Time</TableCell>
                        <TableCell>8.00-8.50</TableCell>
                        <TableCell>8.50-9.40</TableCell>
                        <TableCell>9.40-9.50</TableCell>
                        <TableCell>9.50-10.40</TableCell>
                        <TableCell>10.40-11.30</TableCell>
                        <TableCell>11.30-12.20</TableCell>
                        <TableCell>12.20-1.10</TableCell>
                        <TableCell>1.10-2.00</TableCell>
                        <TableCell>2.00-2.50</TableCell>
                        <TableCell>2.50-3.40</TableCell>
                    </TableRow>
                    {data.map((row) => {
                        return (
                            <TableRow key={row.day}>
                                <TableCell>{row.day}</TableCell>
                                <TableCell>{row.period1}</TableCell>
                                <TableCell>{row.period2}</TableCell>
                                <TableCell>BREAK</TableCell>
                                <TableCell>{row.period3}</TableCell>
                                <TableCell>{row.period4}</TableCell>
                                <TableCell>Lunch</TableCell>
                                <TableCell>{row.period5}</TableCell>
                                <TableCell>{row.period6}</TableCell>
                                <TableCell>{row.period7}</TableCell>
                                <TableCell>{row.period8}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Box >
    );
}
