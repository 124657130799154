import './App.css';
import React from 'react';
import { Auth } from './components/auth';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/home';
import { Finder} from './pages/finder';
import { Tapage } from './pages/tablepage';
import { Rendertable } from './pages/table'
import { Calender } from './pages/calender';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/finder" element={<Finder />} />
        <Route path="/calender" element={<Calender />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/table" element={<Tapage />} />
        <Route path="/srmcal/:year/:branch/:spec/:section" element={<Rendertable />} />
      </Routes>
    </Router>
  );
}




export default App;
