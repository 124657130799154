// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";



const firebaseConfig = {
  // deepcode ignore HardcodedNonCryptoSecret: safe to expose API key
  apiKey: "AIzaSyBiAlDaPTFNbQxbEPW0C26vQt6oEsgN3Tc",
  authDomain: "auth.syn4ck.com",
  projectId: "syn4ck",
  storageBucket: "syn4ck.appspot.com",
  messagingSenderId: "726898217083",
  appId: "1:726898217083:web:18c2def39943c1625cd2de",
  measurementId: "G-1RV4831EFE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);

export const db = getFirestore(app);