//coming soon
import React from "react";
import { Box, Button, Table, TableBody, Typography } from "@mui/material";
import { getDocs } from "firebase/firestore";
import { db } from "../config/firebase";
import { collection } from "firebase/firestore";
import { useState } from "react";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableCell } from "@mui/material";

export const Calender = () => {
  var [data, setData] = useState([]);
  const calenderref = collection(db, "calendar");

  const getData = async () => {
    try {
      const data = await getDocs(calenderref);

      const filtereddata = data.docs.map((doc) => doc.data());

      // sort desc using id

      filtereddata.sort((a, b) => a.id - b.id);

      //   convert timestamp in seconds to date
      filtereddata.forEach((element) => {
        element.date = new Date(
          element.timestamp.seconds * 1000
        ).toDateString();
      });

      // column time in gmt +5:30
      filtereddata.forEach((element) => {
        element.time = new Date(
          element.timestamp.seconds * 1000
        ).toLocaleTimeString();
      }
      );


      setData(filtereddata);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleFetch = () => {
    getData();
  };

  return (
    <Box>
      <Typography variant="h3" style={{ margin: "20px" }}>
        Calender
      </Typography>
      <Button onClick={handleFetch}>Get Data</Button>
      <Table>
        {/* mui table */}
        <TableHead>
          <TableRow>
            <TableCell>Event</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.title}</TableCell>
              <TableCell>{item.cat}</TableCell>
              <TableCell>{item.date}</TableCell>
              <TableCell>{item.time}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
